import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: "/map",name: 'map',
    // redirect: "/three-3d-map",
    meta: { name: '登录' },
    component:()=> import("@/pages/map-animate/map.vue"),
  },
  {
    path: "/callback",name: 'callback',
    // redirect: "/three-3d-map",
    meta: { name: '绑定手机' },
    component:()=> import("@/pages/login/callback.vue"),
  },
  // {
  //   path: "/three-3d-map",
  //   component: import("@/pages/map-animate/map.vue"),
  // },
  //
  // {
  //   path: "/:pathMatch(.*)",
  //   redirect: "/",
  // },
  // {
  //   path: '/login', name: 'Login',
  //   meta: { name: '登录' },
  //   component: () => import('@/pages/login/login.vue')
  // },
  {
    path: '/login', name: 'Login',
    meta: { name: '登录' },
    component: () => import('@/pages/map-animate/map.vue')
  },
  {
    path: '/login-vip', name: 'login',
    meta: { name: '登录' },
    component: () => import('@/pages/login/login.vue')
  },
   {
     path: '/earth', name: 'Earth',
     meta: { name: '地图' },
     component: () => import('@/pages/login/earth.vue')
   },
  {
    path: '/link/offcialWebsite' ,name: 'offcialWebsite',
    meta: { name: '官网' },
    component:()=>import('@/pages/link/offcialWebsite.vue')
  },
  {
    path: '/link/shoppingMall' ,name: 'shoppingMall',
    meta: { name: '商城' },
    component:()=>import('@/pages/link/shoppingMall.vue')
  },
  {
    path: '/link/reception' ,name: 'reception',
    meta: { name: '智慧前台' },
    component:()=>import('@/pages/link/reception.vue')
  },
  {
    path: '/link/smallStore' ,name: 'smallStore',
    meta: { name: '小店通' },
    component:()=>import('@/pages/link/smallStore.vue')
  },
  {
    path: '/link/cosmetology' ,name: 'cosmetology',
    meta: { name: '智慧美容' },
    component:()=>import('@/pages/link/cosmetology.vue')
  },
  {
    path: '/link/medicalAesthetics' ,name: 'medicalAesthetics',
    meta: { name: '智慧医美' },
    component:()=>import('@/pages/link/medicalAesthetics.vue')
  },
  {
    path: '/link/zuyoubao' ,name: 'zuyoubao',
    meta: { name: '足友宝' },
    component:()=>import('@/pages/link/zuyoubao.vue')
  },
  {
    path: '/link/guildHall' ,name: 'guildHall',
    meta: { name: '会馆系统' },
    component:()=>import('@/pages/link/guildHall.vue')
  },
  {
    path: '/link/marketing' ,name: 'marketing',
    meta: { name: '营销平台' },
    component:()=>import('@/pages/link/marketing.vue')
  },
  {
    path: '/link/international' ,name: 'international',
    meta: { name: '国际版' },
    component:()=>import('@/pages/link/international.vue')
  },
  {
    path: '/link/diagnosis' ,name: 'diagnosis',
    meta: { name: '经营诊断平台' },
    component:()=>import('@/pages/link/diagnosis.vue')
  },
  {
    path:'/callback', name: 'callback',
    meta: {name:'微信回调'},
    component:()=>import('@/pages/login/callback.vue')
  },
  {
    path: '/401', name: '401',
    meta: { name: '无权限' },
    component: () => import('@/pages/home/401.vue')
  },
  {
    path: '/402', name: '402',
    meta: { name: '无菜单' },
    component: () => import('@/pages/home/402.vue')
  },
  {
    path: '/', name: 'Home',
    component: () => import('@/pages/index/index/index.vue'),
    children: [
      {
        path: '/',
        meta: { name: '首页', background: true, father: '/' },
        component: () => import('@/pages/home/home/home.vue')
      },
      {
        path: '/news',
        meta: { name: '消息中心', father: '/' },
        component: () => import('@/pages/home/news.vue')
      },
      {
        path: '/menu', name: 'menu',
        meta: { name: '', },
        component: () => import('@/pages/home/menu.vue')
      },
      // {
      //   path: '/store/stored', name: 'stored',
      //   meta: { name: '储值权益资料', father: 'store' },
      //   component: () => import('@/pages/store/stored.vue'),
      // },

      // {
      //   path: '/store/qualification', name: 'qualification',
      //   meta: { name: '资格卡资料', father: 'store' },
      //   component: () => import('@/pages/store/qualification.vue'),
      // },
      

      // ----------------------------------------------------------------------------------------
      // {
      //   path: '/shop/teamwork/workadd', name: 'addteamwork',
      //   meta: { name: '添加拼团', father: 'shop' },
      //   component: () => import('@/pages/shop/teamwork/workadd/index.vue')
      // },
      // {
      //   path: '/shop/manage/proadd', name: 'proadd',
      //   meta: { name: '添加商品', father: 'shop' },
      //   component: () => import('@/pages/shop/manage/proadd/index.vue')
      // },
      // {
      //   path: '/shop/flashsale/saleadd', name: 'addflashsale',
      //   meta: { name: '添加秒杀', father: 'shop' },
      //   component: () => import('@/pages/shop/flashsale/saleadd/index.vue')
      // },
      // {
      //   path: '/shop/order/detail', name: 'orderDetail',
      //   meta: { name: '订单详情', father: 'shop' },
      //   component: () => import('@/pages/shop/order/detail/index.vue')
      // },
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
